var tabActive=true;
function getUrlParameter(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};
function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
          c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
      }
  }
  return "";
}

new function setSessionID(){
  var getCustomerID=getUrlParameter('customerID');
  var getUserID=getUrlParameter('userID');
  if (sessionStorage.tabID && sessionStorage.closedLastTab !== '2') {}
  else {
      tabID = sessionStorage.tabID = ('TAB' + (new Date).getTime().toString(26));
  }
  sessionStorage.closedLastTab = '2';
  $(window).on('unload beforeunload', function () {
      sessionStorage.closedLastTab = '1';
  });
  if(getCustomerID!='')
      document.cookie = "customerID" + sessionStorage.tabID + "=" + getCustomerID + "; path=/";
  if(getUserID!='')
      document.cookie = "userID" + sessionStorage.tabID + "=" + getUserID + "; path=/";

  if(tabActive)
      document.cookie= "activeTabId="+sessionStorage.tabID+"; path=/";



  $(window).blur(function() {
      tabActive=false;
  });

  if(getCookie('customerID' + sessionStorage.tabID) || getCookie('userID' + sessionStorage.tabID)){
      return sessionStorage.tabID;
  }
  else return false
};
/*
function checkIsGoodTabSession(){

  actualCustomerId=getCookie('customerID'+sessionStorage.tabID);
  serverSideActualCustomerId=($('[data-actual-customer-id]').length ? $('[data-actual-customer-id]').attr('data-actual-customer-id') : '');
  activeTabId=getCookie('activeTabId');

  if(actualCustomerId!=serverSideActualCustomerId&&actualCustomerId!=""){
      $('.js-loading-overlay').html("<i class=\"fa fa-cog fa-spin fa-fw\"></i>");
      $('.js-loading-overlay').removeClass('hide');
      //location.reload();
  }
}*/
$(window).focus(function() {
  document.cookie= "activeTabId="+sessionStorage.tabID+"; path=/";
  tabActive=true;
  //checkIsGoodTabSession();
});


jQuery( document ).ready(function() {
    
    $('.image-gallery').slickLightbox({
        itemSelector: '> li > a',
        slick : {
            prevArrow : '<button type="button" class="slick-arrow slick-prev"><span class="icon-arrow"></span></button>',
            nextArrow : '<button type="button" class="slick-arrow slick-next"><span class="icon-arrow"></span></button>'
        },
        caption: 'caption'
      });

    $('.slickslide.image-gallery').slickLightbox({
        itemSelector: '> div > div > li > a',
        slick : {
            prevArrow : '<button type="button" class="slick-arrow slick-prev"><span class="icon-arrow"></span></button>',
            nextArrow : '<button type="button" class="slick-arrow slick-next"><span class="icon-arrow"></span></button>'
        },
        caption: 'caption'
      });

if (!Tools.IsMobile())
{
    jQuery('.js-select2').select2();
}

jQuery('.js-front-page .js-vehiculedefinition').each(function(){

    var frontPage = jQuery(this).parents('.js-front-page');
    var $this = jQuery(this);
    jQuery(this).VehiculeDefinition({
        vehiculetype : jQuery(this).data('type'),
        year : {
            selector : '.js-year',
            type : 'none'
        },
        make : {
            selector : '.js-make',
            type : 'ajax',
            value : jQuery(this).find('.js-make').data('url')
        },
        model : {
            selector : '.js-model',
            type : 'ajax',
            value : jQuery(this).find('.js-model').data('url')
        },
        complete : function(data){
            var url = frontPage.data('url').replace('$1', encodeURI(data.year)).replace('$2', encodeURI(data.make)).replace('$3', encodeURI(data.model));
            if ($this.parents('.js-container-partial--search-engine')[0] && jQuery('#parttype-modal').val() && jQuery('#manufacturer-modal').val() && jQuery('#category-modal').val() != '' && jQuery('#subcategory-modal').val()) {
                url += '?part-type=' + encodeURI(jQuery('#parttype-modal').val()) + '&manufacturer=' + encodeURI(jQuery('#manufacturer-modal').val()) + '&category=' + encodeURI(jQuery('#category-modal').val()) + '&subcategory=' + encodeURI(jQuery('#subcategory-modal').val());
            }
            if(jQuery('#sid-modal').val())
               url = url.replace('$4', jQuery('#sid-modal').val())

            jQuery('.container-search-engine').find('.js-loading-icon').removeClass('hide');
            window.location.href = url;
        }
    });
});

jQuery('.js-garage').on('click', '.js-vehicule-data', function(){
    var url = $(this).closest('.js-garage').data('url');
    var year = $(this).find('.js-row-year').html();
    var make = $(this).find('.js-row-make').html();
    var model = $(this).find('.js-row-model').html();
    
    url = url.replace('$1', encodeURI(year)).replace('$2', encodeURI(make)).replace('$3', encodeURI(model));
    window.location.href = url;
});
	
jQuery('.container-latest-promo').slick({
  dots: false,
  infinite: true,
  speed: 300,
  slidesToShow: 3,
  slidesToScroll: 3,
  prevArrow: '<button type="button" class="slick-arrow slick-prev"><span class="icon-arrow"></span></button>',
 	nextArrow: '<button type="button" class="slick-arrow slick-next"><span class="icon-arrow"></span></button>',
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2, 
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
});

  jQuery(document).foundation({
      equalizer : {
        equalize_on_stack: false
        }
    }); 



}); 

