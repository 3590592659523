jQuery(document).ready(function(){

    jQuery(document).on('click','.js-toggle-menu', function(e) {
        e.preventDefault();
        jQuery(this).toggleClass('is-open');
        jQuery(this).siblings('.js-nav__container').toggleClass('is-open').css({opacity: '1'});
        jQuery('.js-container-partial--my-garage, .js-container-partial--subscription, .js-container-partial--help').css('display', 'none');
        toggleOverlay();
    }); 

    jQuery(document).on('click','.js-link-my-garage, .js-close-my-garage', function(e) {
            e.preventDefault();
            jQuery('.js-container-partial--subscription, .js-container-speed-entry, .js-container-partial--help').fadeOut(); 
        if(!jQuery('.js-toggle-menu').hasClass('is-open')){ 
            var offset_my_garage = jQuery(".js-link-my-garage").offset();
            jQuery('.js-container-partial--my-garage').css( { left: offset_my_garage.left }); 
            jQuery('.js-container-partial--my-garage').fadeToggle(); 
        }else{
            jQuery('.nav__container').animate({opacity:'0'}, { duration: 200, queue: false });
            jQuery('.js-container-partial--my-garage').css({display: 'inline-block', right: '-100%', opacity: '1'}).animate({right:'0%'}, { duration: 200, queue: false }); 
        }
    });

      jQuery(document).on('click','.js-link-help, .js-close-help', function(e) {
            e.preventDefault();
            jQuery('.js-container-partial--subscription, .js-container-speed-entry, .js-container-partial--my-garage').fadeOut(); 
        if(!jQuery('.js-toggle-menu').hasClass('is-open')){ 
            var offset_help = jQuery(".js-link-help").offset();
            jQuery('.js-container-partial--help').css( { left: offset_help.left }); 
            jQuery('.js-container-partial--help').fadeToggle(); 
        }else{
            jQuery('.nav__container').animate({opacity:'0'}, { duration: 200, queue: false });
            jQuery('.js-container-partial--help').css({display: 'inline-block', right: '-100%', opacity: '1'}).animate({right:'0%'}, { duration: 200, queue: false }); 
        }
    });

     jQuery(document).on('click','.js-link-speed-entry, .js-close-speed-entry', function(e) {
        event.preventDefault();
        jQuery('.js-container-partial--subscription, .js-container-partial--my-garage, .js-container-partial--help').fadeOut();
        jQuery('.js-container-speed-entry').fadeToggle();
      });

  

    jQuery(document).scroll(function(e){
        if(jQuery('.js-container-partial--my-garage').css('display') == 'block' ){
            jQuery('.js-container-partial--my-garage').fadeOut();
        }
          if(jQuery('.js-container-partial--help').css('display') == 'block' ){
            jQuery('.js-container-partial--help').fadeOut();
        }
        if(jQuery('.js-nav__container').hasClass('is-open') && Tools.IsMobile()){
            jQuery('.js-nav__container, .js-toggle-menu').removeClass('is-open');
        }
         if(jQuery('.js-container-partial--subscription').css('display') == 'block' ){
            jQuery('.js-container-partial--subscription').fadeOut();
        }
            
    });

    jQuery(document).on('click','.js-button-back-menu',function() {
        container_parent = jQuery(this).parent();
       jQuery('.nav__container').animate({opacity:'1', left: '0'}, { duration: 200, queue: false }); 
       container_parent .animate({right:'-100%'},  function(){
       		container_parent .css({display: 'none'});
       }); 
    });  

    jQuery(document).on('click','.js-link-subscription, .js-close-subscription', function(e) {
        e.preventDefault();
        jQuery('.js-container-partial--my-garage, .js-container-partial--help, .js-container-speed-entry').fadeOut();
        if(!jQuery('.js-toggle-menu').hasClass('is-open')){ 
            var offset_subscription = jQuery(".js-link-subscription").offset();
            jQuery('.js-container-partial--subscription').css( { left: offset_subscription.left }); 
            jQuery('.js-container-partial--subscription').fadeToggle();
        }else{
            jQuery('.nav__container').animate({opacity:'0'}, { duration: 200, queue: false });
            jQuery('.js-container-partial--subscription').css({display: 'inline-block', right: '-100%', opacity: '1'}).animate({right:'0%'}, { duration: 200, queue: false }); 
        }

    });


    jQuery('body').click(function(event) {
        if (!Tools.IsMobile()) {
            var target = jQuery(event.target);
             if (!target.parents(".js-container-partial--subscription").length && !target.parents(".js-nav__container").length && !target.parents(".js-container-partial--help").length  && !target.parents(".js-container-speed-entry").length && !target.parents(".js-container-partial--my-garage").length) {
                   jQuery('.js-container-partial--my-garage, .js-container-partial--help, .js-container-speed-entry, .js-container-partial--subscription').fadeOut();
            }
        }
    });


    // Hide Header on on scroll down
    var didScroll;
    var lastScrollTop = 0;
    var delta = 5;
    var navbarHeight = jQuery('.js-site-header').outerHeight();

    jQuery(window).scroll(function(event){
     
        didScroll = true;
    });
    
    setInterval(function() {

        if (didScroll && jQuery(window).width() < 768 && Tools.IsMobile()) {
            hasScrolled();
            didScroll = false;
        }
    }, 250);


    function hasScrolled() {
        var st = jQuery(this).scrollTop();
        
        // Make sure they scroll more than delta
        if(Math.abs(lastScrollTop - st) <= delta)
            return;
        
        // If they scrolled down and are past the navbar, add class .nav-up.
        // This is necessary so you never see what is "behind" the navbar.
        if (st > lastScrollTop && st > navbarHeight){
            // Scroll Down
            jQuery('.js-site-header').removeClass('nav-down').addClass('nav-up');
        } else {
            // Scroll Up
            if(st + jQuery(window).height() < jQuery(document).height()) {
                jQuery('.js-site-header').removeClass('nav-up').addClass('nav-down');
            }
        }
        
        lastScrollTop = st;
    }


    function toggleOverlay() { 
        if (jQuery('.js-toggle-menu').hasClass('is-open')) {
            jQuery('.js-mobile-overlay').fadeIn();
        } else {
            jQuery('.js-mobile-overlay').fadeOut(); 
        }
    }

  

});