/**********************************************
 * VEHICULE DEFINITION
 * 
 * Plugin which manipulates a year, make and
 * model dropdown lists to define a vehicule 
 * selection by the user
 ***********************************************/
(function ( $ ) {
 
    /**
     * Initializes the Vehicule Definition plugin.
     * @param object params Defines the controls of the plugins and how they should react.
     * The object contains 5 parameters : 
     *  - vehiculetype
     *  - year
     *  - make
     *  - model
     *  - complete (function that recieves the data parameter)
     * The vehiculetype parameters defines the type of vehicule that can be matched with the lists.
     * If this parameter is ommited, then all types of vehicule can me matched
     * The first year/make/model parameters define a dropdown list.
     * The complete parameter defines the function to execute when all 3 lists are filled. 
     * 
     * For each dropdown list, there must be 3 properties that defines it : 
     *  -- selector
     *  -- type (possible values are : AJAX, LIST, NONE)
     *  -- value
     * The selector represents the CSS jquery selector. The type represents the way the data
     * are fetched. AJAX will fetch from server and LIST will display the data listed in the value
     * property. The value property represents the AJAX url in case of a AJAX type or the list
     * of data to display in case of a LIST type. If type is NONE then the list will be left
     * with its initial data.
     * 
     * *** Currently only the AJAX is supported only for the make and model lists ***
     * 
     * 
     * Ex : 
     * {
     *     vehiculetype : 'car',
     *     year : {
     *         selector : '.js-year',
     *         type : LIST
     *         value : {
     *              firstid : 'value a thing',
     *              secondid : 'value something'
     *         }
     *     },
     *     make : {
     *         selector : '.js-year',
     *         type : AJAX
     *         value : 'http://www.something.com/some-url
     *     },
     *     model : {
     *         selector : '.js-model',
     *         type : AJAX
     *         value : 'http://www.something.com/some-url-a-second-time
     *     },
     *     complete : function(data){
     *          var year = data.year;
     *          var make = data.make;
     *          var model = data.model;
     *          
     *          ...
     *     }
     * }
     */
    $.fn.VehiculeDefinition = function(params) {


        
        var vehicule = new VehiculeDefinition(this);
        if (!params.year || !params.make || !params.model)
        {
            return null;
        }
        
        vehicule.yearDef = params.year;
        vehicule.makeDef = params.make;
        vehicule.modelDef = params.model;

         
        
        if (params.vehiculetype)
        {
            vehicule.vehiculetype = params.vehiculetype;
        }
        
        if (params.complete)
        {
            vehicule.complete = params.complete;   
        }
        
        vehicule.BindControls();
        
        return vehicule;
    

        /**
         * Vehicule definition class. It defines the vehicule in it's whole
         * @param jQueryElement container jQuery Element that represents the container
         * of the dropdown lists
         */
        function VehiculeDefinition(container){
            this.container = container;
            this.yearDef = {};
            this.makeDef = {};
            this.modelDef = {};

            this.year = null;
            this.make = null;
            this.model = null;
            
            this.vehiculetype = null;

            this.complete = function(data){};

            this.BindControls = function(){
                //jQuery Elements
                this.year = this.container.find(this.yearDef.selector);
                this.make = this.container.find(this.makeDef.selector);
                this.model = this.container.find(this.modelDef.selector);
                
                //Events
                var vehicule = this;     
                var target;
                var segment;
                var vehicule_segment;
                var success = function(data) {

                    var targetLabel = target.find('.js-label');
                    var html = '';
                    if (targetLabel)
                    {
                        html = '<option class="js-label" value="">' + targetLabel.html() + '</option>';


                    }
                    if (!data.error)
                    {
                        for (var i = 0; i < data.values.length; i++)
                        {
                            html += '<option value="' + data.values[i] + '">' + data.values[i] + '</option>';
                        }
                    }
                    target.html(html);
                    if (!Tools.IsMobile() && target.hasClass('js-select2')) {
                        target.select2("open");
                    } else {
                        target.simulate("mousedown");
                    }
                };
                this.container.on('change', vehicule.yearDef.selector, function(){
                    if (vehicule.makeDef.type && vehicule.makeDef.type.toUpperCase() == 'AJAX' && vehicule.makeDef.value)
                    {
                        var url = vehicule.makeDef.value;
                        target = vehicule.make;
                        var value = $(this).val();
                        var segment = $('.js-segment-tab.active').attr('id');
                        

                         if (segment == 'light-vehicles') {
                                vehicule_segment = 'Car,Truck,Van,ATV,Offroad Motorcycle,Snowmobile,Utility Vehicle,Scooter';
                           } else if (segment == 'heavy-trucks') {
                                vehicule_segment = 'Medium/Heavy Truck'; 
                           }
                           
                        if ($('.container-search-tool--modal.promo').length) {
                            vehicule_segment = 'Car,Truck,Van,ATV,Offroad Motorcycle,Snowmobile,Utility Vehicle,Scooter,Medium/Heavy Truck'; 
                        }
                        
                        $.ajax({
                            url:url,
                            dataType: 'JSON',
                            type: 'POST',
                            data: {
                                year : value,
                                type : vehicule_segment
                            },
                            success: success
                        });
                    }
                });
                this.container.on('change', vehicule.makeDef.selector, function(){
                    if (vehicule.modelDef.type && vehicule.modelDef.type.toUpperCase() == 'AJAX' && vehicule.modelDef.value)
                    {
                        var url = vehicule.modelDef.value;
                        target = vehicule.model;
                        var value = $(this).val();
                        var yearValue = vehicule.year.val();
                        var segment = $('.js-segment-tab.active').attr('id');
                       

                         if (segment == 'light-vehicles') {
                                vehicule_segment = 'Car,Truck,Van,ATV,Offroad Motorcycle,Snowmobile,Utility Vehicle,Scooter';
                           } else if (segment == 'heavy-trucks') {
                                vehicule_segment = 'Medium/Heavy Truck'; 
                           }

                        if ($('.container-search-tool--modal.promo').length) {
                            vehicule_segment = 'Car,Truck,Van,ATV,Offroad Motorcycle,Snowmobile,Utility Vehicle,Scooter,Medium/Heavy Truck'; 
                        }

                        $.ajax({
                            url:url,
                            dataType: 'JSON',
                            type: 'POST',
                            data: {
                                year : yearValue,
                                make : value,
                                type : vehicule_segment
                            },
                            success: success
                        });
                    }
                });
                this.container.on('change', this.modelDef.selector, function(){
                    var data = {
                        year : vehicule.year.val(),
                        make : vehicule.make.val(),
                        model : vehicule.model.val()
                    };

                    if (data.year && data.make && data.model)
                    {
                        vehicule.complete(data);
                    }
                });
            };

            /**
             * Fills all the list defined as type LIST in their definition
             */
            this.FillLists = function()
            {
                var html;
                var targetLabel;
                var fillList = function(list, data){
                    targetLabel = list.find('.js-label');
                    html = '';
                    if (targetLabel)
                    {
                        html = '<option class="js-label" value="">' + targetLabel.html() + '</option>';
                    }

                    for (var key in data)
                    {
                        html += '<option value="' + key + '">' + data[key] + '</option>';
                    }

                    list.html(html);
                };
                if (this.yearDef.type && this.yearDef.type.toUpperCase() == 'LIST')
                {
                    fillList(this.year, this.yearDef.value);
                }
                if (this.makeDef.type && this.makeDef.type.toUpperCase() == 'LIST')
                {
                    fillList(this.make, this.makeDef.value);
                }
                if (this.modelDef.type && this.modelDef.type.toUpperCase() == 'LIST')
                {
                    fillList(this.model, this.modelDef.value);
                }
            };
        }
    };
 
}( jQuery ));