jQuery(document).ready(function(){


    function changeSegment(segment){
        var check_active;
        if(segment.hasClass('active')){
            check_active = 'active';
        }
        jQuery('.js-segment li').removeClass('active');
        segment.addClass('active');

      
        if(jQuery('body').hasClass('home')){
           jQuery('.js-container-partial--tools-and-accessories').fadeOut(); 
            jQuery('.js-container-search-tool--banner').fadeIn();
        
            jQuery('.js-container-modal-group').removeClass('active');      
            $segment_id = '.js-container-' + segment.attr('id');
            jQuery($segment_id).addClass('active');
            jQuery($segment_id).find('.js-container-default').addClass('active');
           // jQuery('.js-search-modal .js-tab-text').css('display', 'none');
            jQuery('.js-search-modal .js-tab-text[data-type=' + segment.attr('id') + ']').removeAttr('style');
            jQuery('.js-search-modal .js-search-by-garage-select option.js-default-value').each(function(){
                jQuery(this).html(jQuery(this).data(segment.attr('id')));
            });
             changeBanner(segment.attr('id'), null, !segment.hasClass('js-no-seg'));
           
            changeTitle(segment.attr('id'));
            changeTab(jQuery('.js-tab-default'));
     
        }else{
            current_url = window.location.href;
            if ( current_url.indexOf('/en/') !== -1) { 
               window.location.href = "/en/";
            } else {
               window.location.href = "/";
            }

           setCookie(segment.attr('id'), null, !segment.hasClass('js-no-seg'));
        }
    }
    function changeBanner(segment, persona, cookie){
        initBannerHome(segment);
       setCookie(segment, persona, cookie);
    }
    function changeTitle(segment){
        if(segment == 'light-vehicles'){
            jQuery('.js-title-brand-light-vehicles').removeClass('hide');
            jQuery('.js-title-brand-heavy-trucks').addClass('hide');
        }else{
            jQuery('.js-title-brand-light-vehicles').addClass('hide');
            jQuery('.js-title-brand-heavy-trucks').removeClass('hide');
        }
         
    }
  
    function changeTab(tab, check){

        jQuery('.js-container-partial--search-engine .js-tab').removeClass('active');
        tab.addClass('active');
        jQuery('.js-container-partial--search-engine .js-tab-pane').removeClass('active');
        $tab_pane_id = '.js-container-partial--search-engine #tab_pane_' + tab.attr('id'); 
        jQuery($tab_pane_id).addClass('active');
        tab.parent().parent().find('input[type=text]').focus();
        if(check == 1)
            jQuery('.js-container-default').addClass('active');
     } 
 

    function toggleSearchPanel(isPartNumber){
        jQuery('.container-search-tool--banner').slideToggle('slow');
        
        if (isPartNumber) {
            jQuery('.container-search-tool--banner').find('#car_part_number').click();
        }
    }
    jQuery('.js-segment').on('click','li#heavy-trucks, li#light-vehicles', function() {
            changeSegment(jQuery(this));
    });
    jQuery('.js-segment').on('click','li#tools-and-accessories', function() {
            setCookie('tools-and-accessories', null, true);
    });
    jQuery('.js-container-partial--search-engine').on('click','.js-tab', function(){
            changeTab(jQuery(this));
    });
    jQuery('.js-container-partial--search-engine').on('click','.js-segment', function(){
            changeTab(jQuery('.js-tab-default'), 1);
    }); 
 
    jQuery('.js-container-partial--search-engine').on('click','.js-container-icon',function() {
        var label_sibling = jQuery(this).siblings('.js-persona-name');
       
    });
    jQuery('.js-container-partial--search-engine').on('submit','.js-search-part-number',function(e) {
        var form = jQuery(this).closest('.js-search-part-number');
        var text = form.find('.js-search-part-number-text');

        if (text.val() != '') {
            var url = form.attr('action').replace('$1', text.val());
            window.location.href = url;
        }
        return false;
    });
    jQuery('.js-container-partial--search-engine').on('change','.js-search-by-garage-select',function(e) {
        var form = jQuery(this).closest('.js-search-by-garage');
        var value = jQuery(this).val();
        var url = form.attr('action');

        if (value != "") {
            var parts = value.split('|');
            url = url.replace('$1', parts[0]).replace('$2', parts[1]).replace('$3', parts[2]);

            window.location.href = url;
        }
    });
   // changeBanner(jQuery('.js-segment .js-segment-tab.active').attr('id'), jQuery('.js-container-persona.active .js-persona-name').attr('id'), false);

     if(jQuery('.active').hasClass('container-tools-and-accessories')){
               jQuery('.container-search-engine').addClass('full');
     }
    jQuery('.js-container-search-tool--banner .fa-close, .js-container-content-step-1, .js-container-search-tool--banner .js-slick-lightbox-close').on('click', function(){
        var isStep3 = false;
        if (jQuery(this).closest('.js-step3-breadcrumb.js-isPartNumber')[0]) {
            isStep3 = true;
        }
        toggleSearchPanel(isStep3);
    });
    /**
    * Modal search engine  
    */ 
    jQuery('.js-bestseller').on('click', function(){
        var modal = jQuery('.js-container-search-tool--modal');
        var background = jQuery('.js-container-search-tool--overlay');
        
        background.fadeIn();
        modal.fadeIn();
        changeTab(jQuery('.js-tab-default'));
        modal.find('#parttype-modal').val(jQuery(this).data('parttype'));
        modal.find('#manufacturer-modal').val(jQuery(this).data('manufacturer'));
        modal.find('#category-modal').val(jQuery(this).data('category'));
        modal.find('#subcategory-modal').val(jQuery(this).data('subcategory'));
    });
     jQuery('.js-container-search-tool--modal').on('click','.js-close-modal-best-seller', function() {
        jQuery('.js-container-search-tool--overlay').fadeOut();
        jQuery('.js-container-search-tool--modal').fadeOut(); 
     });

      jQuery(document).scroll(function(e){
        jQuery('.js-container-search-tool--overlay').fadeOut();
        jQuery('.js-container-search-tool--modal').fadeOut(); 
    });
    jQuery('.header-logo img, .header-navigation--primary li:first-of-type').on('click', function(){
        var cookie = getCookie("kobrex-segment"),
        substring = "tools"; 
        //console.log(cookie);
        if(cookie.indexOf(substring) !== -1)
            Cookies.set('kobrex-segment', 'light-vehicles', { expires: 365 }); 
        Cookies.set('kobrex-persona', '', { expires: 365 }); 
    });
});



