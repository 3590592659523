jQuery('document').ready(function(){
    jQuery('.js-tools-category').click(function(){
        var category_id = jQuery(this).data('category');
        jQuery('.js-tools-category').removeClass('active'); 
        jQuery(this).addClass('active');
        jQuery('.container-subcategory').css('display', 'none');
           jQuery('.js-'+category_id).fadeIn();
        
    });

    jQuery('.js-tools-category:first-of-type').addClass('active');
    var category_id = jQuery('.js-tools-category:first-of-type').data('category');
    jQuery('.js-'+category_id).fadeIn();
});